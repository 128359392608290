import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import UnitConverter from './components/UnitConverter';
import Sitemap from './components/Sitemap';
import Layout from './components/Layout';

function App() {
  return (
    <HelmetProvider>
      <Router>
        <Layout>
        <Routes>
          <Route path="/" element={<UnitConverter />} />
          <Route path="/:category" element={<UnitConverter />} />
          <Route path="/:category/:conversion" element={<UnitConverter />} />
          <Route path="/sitemap" element={<Sitemap/>} />

        </Routes>
        </Layout>
      </Router>
    </HelmetProvider>
  );
}

export default App;