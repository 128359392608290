import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const availableUnits = {
  length: [
    'meter', 'kilometer', 'decimeter', 'centimeter', 'millimeter', 
    'micrometer', 'nanometer', 'mile', 'yard', 'foot', 
    'inch', 'exameter', 'petameter', 'terameter', 
    'gigameter', 'megameter', 'hectometer', 'dekameter', 'micron', 
    'picometer', 'femtometer', 'attometer', 'megaparsec',
    'parsec', 'league', 'kiloyard', 'furlong', 
    'chain', 'rope', 'rod', 
    'perch', 'pole', 'fathom', 'ell', 
    'fermi', 'arpent', 'pica', 'point', 'twip', 'aln', 'famn', 'caliber'
  ],
  temperature: ['celsius', 'fahrenheit', 'kelvin'],
  area: ['square-meter', 'square-kilometer', 'square-centimeter', 'square-millimeter', 
         'hectare', 'acre', 'square-mile', 'square-yard', 'square-foot', 'square-inch'],
  time: ['second', 'minute', 'hour', 'day', 'week', 'month', 'year'],
  weight: ['milligram', 'gram', 'kilogram', 'tonne', 'ounce', 'pound', 'stone'],
  volume: ['milliliter', 'liter', 'cubic-meter', 'cubic-centimeter', 'gallon', 
           'quart', 'pint', 'cup', 'fluid-ounce']
};

const normalizeUnitName = (unit) => {
  return unit.toLowerCase().replace(/ /g, '-').replace(/[^\w-]/g, '');
};

const Sitemap = () => {

  const generateLinks = () => {
    const links = [];

    Object.keys(availableUnits).forEach((category) => {
      availableUnits[category].forEach((fromUnit) => {
        availableUnits[category].forEach((toUnit) => {
          if (fromUnit !== toUnit) {
            const link = `/${category}/${normalizeUnitName(fromUnit)}-to-${normalizeUnitName(toUnit)}`;
            links.push(
              <li key={link}>
                <Link to={link} className="underline">
                  {`${fromUnit.charAt(0).toUpperCase() + fromUnit.slice(1)} to ${toUnit.charAt(0).toUpperCase() + toUnit.slice(1)} (${category.charAt(0).toUpperCase() + category.slice(1)})`}
                </Link>
              </li>
            );
          }
        });
      });
    });

    return links;
  };

  return (
    <>
      <Helmet>
        <title>Sitemap | Unit Converter</title>
        <meta name="description" content="Sitemap for the Unit Converter website, listing all available conversion pages." />
      </Helmet>
      <div className="sitemap-container">
        <h1 className="text-3xl font-bold mb-6">Sitemap</h1>
        <ul className="list-disc list-inside">{generateLinks()}</ul>
      </div>
    </>
  );
};

export default Sitemap;
