// Import necessary packages and components from React and react-router-dom
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Card, CardContent } from '../ui/card';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../ui/select';
import { Input } from '../ui/input';
import { Button } from '../ui/button';
import LogoImage from '../assets/images/FeetToInchesLogo.png';


// Normalize unit names for consistent use in URLs
const normalizeUnitName = (unit) => {
  return unit.toLowerCase().replace(/ /g, '-').replace(/[^\w-]/g, '');
};
// Available units for length category
const availableUnits = {
  length: [
    'meter', 'kilometer', 'decimeter', 'centimeter', 'millimeter', 
    'micrometer', 'nanometer', 'mile', 'yard', 'foot', 
    'inch', 'exameter', 'petameter', 'terameter', 
    'gigameter', 'megameter', 'hectometer', 'dekameter', 'micron', 
    'picometer', 'femtometer', 'attometer', 'megaparsec',
    'parsec', 'league',  'kiloyard', 'furlong', 
    'chain',  'rope', 'rod', 
    'perch', 'pole', 'fathom',  'ell', 
    'fermi', 'arpent', 'pica', 'point', 'twip', 'aln', 'famn', 'caliber'
  ],
  temperature: [
    'celsius', 'fahrenheit', 'kelvin'
  ],
  area: [
    'square-meter', 'square-kilometer', 'square-centimeter', 'square-millimeter', 
    'hectare', 'acre', 'square-mile', 'square-yard', 'square-foot', 'square-inch'
  ],
  time: [
    'second', 'minute', 'hour', 'day', 'week', 'month', 'year'
  ],
  weight: [
    'milligram', 'gram', 'kilogram', 'tonne', 'ounce', 'pound', 'stone'
  ],
  volume: [
    'milliliter', 'liter', 'cubic-meter', 'cubic-centimeter', 'gallon', 'quart', 'pint', 'cup', 'fluid-ounce'
  ]
};
const timeToSeconds = {
  'second': 1,
  'minute': 60,
  'hour': 3600,
  'day': 86400,
  'week': 604800,
  'month': 2592000, // Approximate
  'year': 31536000 // Approximate
};
const weightToGrams = {
  'milligram': 0.001,
  'gram': 1,
  'kilogram': 1000,
  'tonne': 1000000,
  'ounce': 28.3495,
  'pound': 453.592,
  'stone': 6350.29
};
const volumeToLiters = {
  'milliliter': 0.001,
  'liter': 1,
  'cubic-meter': 1000,
  'cubic-centimeter': 0.001,
  'gallon': 3.78541,
  'quart': 0.946353,
  'pint': 0.473176,
  'cup': 0.24,
  'fluid-ounce': 0.0295735
};

const temperatureConversions = {
  'celsius-to-fahrenheit': (c) => (c * 9/5) + 32,
  'fahrenheit-to-celsius': (f) => (f - 32) * 5/9,
  'celsius-to-kelvin': (c) => c + 273.15,
  'kelvin-to-celsius': (k) => k - 273.15,
  'fahrenheit-to-kelvin': (f) => (f - 32) * 5/9 + 273.15,
  'kelvin-to-fahrenheit': (k) => (k - 273.15) * 9/5 + 32
};
const areaToSquareMeters = {
  'square-meter': 1,
  'square-kilometer': 1e6,
  'square-centimeter': 1e-4,
  'square-millimeter': 1e-6,
  'hectare': 1e4,
  'acre': 4046.8564224,
  'square-mile': 2.59e6,
  'square-yard': 0.83612736,
  'square-foot': 0.09290304,
  'square-inch': 0.00064516
};

// Conversion factors to base unit (meters) for length
const lengthToMeters = {
  'meter': 1,
  'kilometer': 1000,
  'decimeter': 0.1,
  'centimeter': 0.01,
  'millimeter': 0.001,
  'micrometer': 1e-6,
  'nanometer': 1e-9,
  'mile': 1609.34,
  'yard': 0.9144,
  'foot': 0.3048,
  'inch': 0.0254,
  'exameter': 1e18,
  'petameter': 1e15,
  'terameter': 1e12,
  'gigameter': 1e9,
  'megameter': 1e6,
  'hectometer': 100,
  'dekameter': 10,
  'micron': 1e-6,
  'picometer': 1e-12,
  'femtometer': 1e-15,
  'attometer': 1e-18,
  'megaparsec': 3.086e22,
  'parsec': 3.086e16,
  'league': 4828.03,
  'kiloyard': 914.4,
  'furlong': 201.168,
  'chain': 20.1168,
  'rope': 6.096,
  'rod': 5.0292,
  'perch': 5.0292,
  'pole': 5.0292,
  'fathom': 1.8288,
  'ell': 1.143,
  'fermi': 1e-15,
  'arpent': 58.5216,
  'pica': 0.004217,
  'point': 0.0003514,
  'twip': 0.0000176,
  'aln': 0.5939,
  'famn': 1.781,
  'caliber': 0.000254
};

// Functional component for unit conversion
const UnitConverter = () => {
  const { category: urlCategory, conversion } = useParams(); // Get URL parameters
  const navigate = useNavigate(); // Initialize navigation hook
  const [category, setCategory] = useState(urlCategory || 'length'); // State for category, default 'length'
  const [fromUnit, setFromUnit] = useState(() => {
    if (conversion) {
      const [from] = conversion.split('-to-');
      return normalizeUnitName(from) || 'foot'; // Default to 'foot' if not specified
    }
    return 'foot';
  });
  const [toUnit, setToUnit] = useState(() => {
    if (conversion) {
      const [, to] = conversion.split('-to-');
      return normalizeUnitName(to) || 'inch'; // Default to 'inch' if not specified
    }
    return 'inch';
  }); 
   const [inputValue, setInputValue] = useState(''); // State for input value
  const [result, setResult] = useState(null); // State for conversion result
  const [isConverted, setIsConverted] = useState(false); // State for tracking conversion trigger
  const [conversionTable, setConversionTable] = useState([]);

  // Update state based on URL parameters
  useEffect(() => {
    if (urlCategory) {
      setCategory(urlCategory);
    }
    if (conversion) {
      const [from, to] = conversion.split('-to-');
      setFromUnit(normalizeUnitName(from));
      setToUnit(normalizeUnitName(to));
    }
  }, [urlCategory, conversion]);

  // Update URL based on state changes
  useEffect(() => {
    if (category && fromUnit && toUnit) {
      navigate(`/${category}/${normalizeUnitName(fromUnit)}-to-${normalizeUnitName(toUnit)}`, { replace: true });
    } else if (category) {
      navigate(`/${category}`, { replace: true });
    }
  }, [category, fromUnit, toUnit, navigate]);

  // Handle unit conversion based on selected units and input value
  const handleConvert = () => {
    setIsConverted(true);
  
    if (fromUnit && toUnit && inputValue) {
  
      const normalizedFromUnit = normalizeUnitName(fromUnit);
      const normalizedToUnit = normalizeUnitName(toUnit);
  
      if (category === 'length') {
        const fromFactor = lengthToMeters[normalizedFromUnit];
        const toFactor = lengthToMeters[normalizedToUnit];
  
        if (fromFactor !== undefined && toFactor !== undefined && !isNaN(inputValue)) {
          const convertedValue = (parseFloat(inputValue) * fromFactor) / toFactor;
          setResult(convertedValue.toFixed(2));
          generateConversionTable(fromFactor, toFactor);
        } else {
          setResult(null);
        }
      } else if (category === 'area') {
        const fromFactor = areaToSquareMeters[normalizedFromUnit];
        const toFactor = areaToSquareMeters[normalizedToUnit];
  
        if (fromFactor !== undefined && toFactor !== undefined && !isNaN(inputValue)) {
          const convertedValue = (parseFloat(inputValue) * fromFactor) / toFactor;
          setResult(convertedValue.toFixed(2));
          generateConversionTable(fromFactor, toFactor);
        } else {
          setResult(null);
        }
      } else if (category === 'time') {
        const fromFactor = timeToSeconds[normalizedFromUnit];
        const toFactor = timeToSeconds[normalizedToUnit];
  
        if (fromFactor !== undefined && toFactor !== undefined && !isNaN(inputValue)) {
          const convertedValue = (parseFloat(inputValue) * fromFactor) / toFactor;
          setResult(convertedValue.toFixed(2));
        } else {
          setResult(null);
        }
      } else if (category === 'temperature') {
        if (fromUnit === toUnit) {
          // If both units are the same, set the result to the input value
          setResult(parseFloat(inputValue).toFixed(2));
        }else{
          const conversionKey = `${normalizedFromUnit}-to-${normalizedToUnit}`;
          const convert = temperatureConversions[conversionKey];
    
          if (convert && !isNaN(inputValue)) {
            const convertedValue = convert(parseFloat(inputValue));
            setResult(convertedValue.toFixed(2));
          } else {
            setResult(null);
          }
        }
        
      } else if (category === 'weight') {
        const fromFactor = weightToGrams[normalizedFromUnit];
        const toFactor = weightToGrams[normalizedToUnit];
  
        if (fromFactor !== undefined && toFactor !== undefined && !isNaN(inputValue)) {
          const convertedValue = (parseFloat(inputValue) * fromFactor) / toFactor;
          setResult(convertedValue.toFixed(2));
        } else {
          setResult(null);
        }
      } else if (category === 'volume') {
        const fromFactor = volumeToLiters[normalizedFromUnit];
        const toFactor = volumeToLiters[normalizedToUnit];
  
        if (fromFactor !== undefined && toFactor !== undefined && !isNaN(inputValue)) {
          const convertedValue = (parseFloat(inputValue) * fromFactor) / toFactor;
          setResult(convertedValue.toFixed(2));
        } else {
          setResult(null);
        }
      } else {
        setResult(null);
      }
    } else {
      setResult(null);
    }
  };
  
  
  
  const generateConversionLinks = () => {
    return availableUnits[category]?.map((unit) => {
      if (unit !== fromUnit) {
        const link = `/${category}/${normalizeUnitName(fromUnit)}-to-${normalizeUnitName(unit)}`;
        const displayText = `${fromUnit.charAt(0).toUpperCase() + fromUnit.slice(1)} to ${unit.charAt(0).toUpperCase() + unit.slice(1)}`;
        
        return (
          <li key={unit}>
            <a
              className="text-1xl underline decoration-blue-400"
              href={link}
           
              title={`Convert ${displayText}`} // Tooltip text for accessibility
              aria-label={`Convert ${displayText}`} // ARIA label for screen readers
            >
              {displayText}
            </a>
          </li>
        );
      }
      return null;
    });
  };
  


  
  const generateConversionTable = (fromFactor, toFactor) => {
    const increments = [0.1, 0.5, 1, 2, 3, 5, 10, 20, 50, 100, 1000]; // Define your increments
    const table = increments.map(increment => ({
      value: increment,
      converted: ((increment * fromFactor) / toFactor).toFixed(2)
    }));
    setConversionTable(table);
  };

  // Page title and description based on selected units
  const pageTitle = `Convert ${fromUnit} to ${toUnit} | Feet to Inches`;
  const pageDescription = fromUnit && toUnit
  ? `Convert ${fromUnit} to ${toUnit} quickly and accurately. Our unit converter at Feet to Inches provides precise ${category} conversions for all your needs, including ${fromUnit} to ${toUnit} conversions. Perfect for measurements in ${category}.`
  : `Convert between various ${category} units quickly and accurately. Our unit converter at Feet to Inches provides precise conversions for all your measurement needs.`;

  const generateDynamicKeywords = () => {
    const baseKeywords = [
      'unit converter', 
      'length conversion', 
      'unit conversion tool', 
      `${fromUnit} to ${toUnit} conversion`,
      `${fromUnit} conversion`, 
      `${toUnit} conversion`, 
      'conversion calculator'
    ];
    
    const specificKeywords = [
      `${category} units`, 
      `${fromUnit} to ${toUnit}`, 
      `convert ${fromUnit} to ${toUnit}`, 
      `how to convert ${fromUnit} to ${toUnit}`, 
      `${fromUnit} to ${toUnit} calculator`, 
      `${fromUnit} to ${toUnit} length converter`
    ];
    
    return baseKeywords.concat(specificKeywords).join(', ');
  };
  return (
    <>
      {/* Set page title and meta description */}
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta name="keywords" content={generateDynamicKeywords()} />
      </Helmet>
      <div className="min-h-screen w-full bg-[#663aedfc] flex items-center justify-center p-4">
        
        <div className="w-full max-w-3xl">
          <div className="flex justify-center mb-5">
            <img src={LogoImage} alt="FeetToInches.com Logo" className="h-48 w-auto" />
          </div>
          
          <h1 className="text-2xl mb-10 font-bold text-center mb-2 text-transparent bg-clip-text bg-gradient-to-r from-[#adfbda] via-[#35c3ff] via-[#fda399] via-[#76d880] to-[#ebf38b]">
          Feet to Inches | Accurate Length Conversion & Unit Converter for All Measuremnts
          </h1>
         
          <Card className="mb-8">
            <CardContent className="p-4">
              <h2 className="text-xl font-semibold mb-4">Unit Converter</h2>
              <div className="space-y-4">
                <Select value={category} onValueChange={setCategory}>
                  <SelectTrigger>
                    <SelectValue placeholder="Select category" />
                  </SelectTrigger>
                  <SelectContent>
                    {Object.keys(availableUnits).map((cat) => (
                      <SelectItem key={cat} value={cat}>
                        {cat.charAt(0).toUpperCase() + cat.slice(1)}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>

                <div className="flex space-x-2">
                  <Select value={fromUnit} onValueChange={(value) => { setFromUnit(value); setIsConverted(false); }}>
                    <SelectTrigger>
                      <SelectValue placeholder="From" />
                    </SelectTrigger>
                    <SelectContent>
                      {availableUnits[category]?.map((unit) => (
                        <SelectItem key={unit} value={unit}>
                          {unit.charAt(0).toUpperCase() + unit.slice(1)}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <span className="self-center">To</span>
                  <Select value={toUnit} onValueChange={(value) => { setToUnit(value); setIsConverted(false); }}>
                    <SelectTrigger>
                      <SelectValue placeholder="To" />
                    </SelectTrigger>
                    <SelectContent>
                      {availableUnits[category]?.map((unit) => (
                        <SelectItem key={unit} value={unit}>
                          {unit.charAt(0).toUpperCase() + unit.slice(1)}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>

                <Input
                  type="number"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  placeholder="Enter value"
                  className="w-full"
                />

                <Button onClick={handleConvert} className="w-full bg-gray-900 text-white hover:bg-gray-800">
                  Convert
                </Button>

                {isConverted && result !== null && (
                  <div className="mt-4 p-6 bg-gray-100 rounded-md">
                    <p className="text-4xl font-semibold text-center text-purple-900">{result} {toUnit}</p>
                  </div>
                )}
              </div>
            </CardContent>
          </Card>

          {fromUnit && toUnit && (
  <div className="mt-8 p-6 bg-white rounded-lg shadow">
    <h3 className="text-lg font-semibold mb-2">
      How to convert {fromUnit} to {toUnit}:
    </h3>
    {category === 'length' && (
      <p className="whitespace-pre-line">
        Conversion from {fromUnit} to {toUnit} can be done using a conversion factor. 
        Please enter the value and click convert to see the result.
      </p>
    )}
    {category === 'temperature' && (
      <p className="whitespace-pre-line">
        Conversion from {fromUnit} to {toUnit} is based on the temperature conversion formula. 
        For example, to convert {fromUnit} to {toUnit}, use the formula: 
        {fromUnit === 'celsius' && toUnit === 'fahrenheit' && ' (°C * 9/5) + 32 = °F'}
        {fromUnit === 'fahrenheit' && toUnit === 'celsius' && ' (°F - 32) * 5/9 = °C'}
        {fromUnit === 'celsius' && toUnit === 'kelvin' && ' °C + 273.15 = K'}
        {fromUnit === 'kelvin' && toUnit === 'celsius' && ' K - 273.15 = °C'}
        {fromUnit === 'fahrenheit' && toUnit === 'kelvin' && ' (°F - 32) * 5/9 + 273.15 = K'}
        {fromUnit === 'kelvin' && toUnit === 'fahrenheit' && ' (K - 273.15) * 9/5 + 32 = °F'}
      </p>
    )}
     {category === 'area' && (
      <p className="whitespace-pre-line">
        Conversion from {fromUnit} to {toUnit} is done by multiplying by the area conversion factor. 
        For example, to convert {fromUnit} to {toUnit}, use the factor: 
        {`1 ${fromUnit} = ${areaToSquareMeters[normalizeUnitName(toUnit)]} ${toUnit}`}.
      </p>
    )}
    {category === 'time' && (
      <p className="whitespace-pre-line">
        Conversion from {fromUnit} to {toUnit} involves using the time conversion factor. 
        For example, to convert {fromUnit} to {toUnit}, use the factor: 
        {`1 ${fromUnit} = ${timeToSeconds[normalizeUnitName(toUnit)]} ${toUnit}`}.
      </p>
    )}
    {category === 'weight' && (
      <p className="whitespace-pre-line">
        Conversion from {fromUnit} to {toUnit} is based on weight conversion factors. 
        For example, to convert {fromUnit} to {toUnit}, use the factor: 
        {`1 ${fromUnit} = ${weightToGrams[normalizeUnitName(toUnit)]} ${toUnit}`}.
      </p>
    )}
    {category === 'volume' && (
      <p className="whitespace-pre-line">
        Conversion from {fromUnit} to {toUnit} involves using the volume conversion factor. 
        For example, to convert {fromUnit} to {toUnit}, use the factor: 
        {`1 ${fromUnit} = ${volumeToLiters[normalizeUnitName(toUnit)]} ${toUnit}`}.
      </p>
    )}
  </div>
)}

{isConverted && conversionTable.length > 0 && (
  <div className="mt-8 p-6 bg-white rounded-lg shadow">
    <h3 className="text-lg font-semibold mb-2">Conversion:</h3>
    <table className="min-w-full bg-white border border-gray-200">
      <thead>
        <tr>
          <th className="border-b border-gray-200 px-4 py-2 text-left">From ({fromUnit})</th>
          <th className="border-b border-gray-200 px-4 py-2 text-left">To ({toUnit})</th>
        </tr>
      </thead>
      <tbody>
        {conversionTable.map(({ value, converted }, index) => (
          <tr key={index}>
            <td className="border-b border-gray-200 px-4 py-2">{value}</td>
            <td className="border-b border-gray-200 px-4 py-2">{converted}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
)}

{isConverted && category === 'temperature' && (
  <div className="mt-8 p-6 bg-white rounded-lg shadow">
    <h3 className="text-lg font-semibold mb-2"> Temperature Conversions From ({fromUnit}) To ({toUnit})</h3>
    <ul>
      <li>0°C = 32°F</li>
      <li>100°C = 212°F</li>
      <li>0°C = 273.15K</li>
      <li>100°F = 37.78°C</li>
      <li>100°F = 310.93K</li>
      <li>0K = -273.15°C</li>
    </ul>
  </div>
)}

            {fromUnit && (
            <div className="mt-8 p-6 bg-white rounded-lg shadow">
              <h3 className="text-lg font-semibold mb-2">Convert {fromUnit.charAt(0).toUpperCase() + fromUnit.slice(1)} to Other Length Units</h3>
              <ul>
                {generateConversionLinks()}
              </ul>
            </div>
          )}
        </div>
      </div>
  
    </>
  );
};

export default UnitConverter;
