// Footer.js
import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (

    
    <footer className="pt-5 pb-5 border-t border-gray-300">
      <div className="flex justify-center space-x-4 ">
        {/* <Link to="/about-us" className="underline">About Us</Link>
        <Link to="/terms-of-use" className="underline">Terms of Use</Link>
        <Link to="/privacy-policy" className="underline">Privacy Policy</Link> */}
        {/* <Link to="/sitemap" className="underline">Sitemap</Link> */}
      </div>
      <p className="text-center text-gray-600">feettoinches.com © 2024 All rights reserved.</p>
    </footer>
  );
};

export default Footer;
