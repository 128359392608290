// Layout.js (if you have a layout component)
import React from 'react';
import Footer from './Footer'; // Adjust the path as needed
import { SpeedInsights } from "@vercel/speed-insights/react"
import { Analytics } from "@vercel/analytics/react"

const Layout = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen">
      <main className="flex-grow">
        {children}
        <SpeedInsights/>
        <Analytics/>
      </main>
    
      <Footer />
    </div>
  );
};

export default Layout;
